import * as React from "react"

const defaultValues = {
  isOpen: false,
  loading: false,
  onOpen: () => { },
  onClose: () => { },
  addPriceToCart: () => { },
  removeLineItem: () => { },
  updateLineItem: () => { },
  updateLineItemStock: () => { },
  clearCart: () => { },
  clearStock: () => { },
  lineItems: [],
  stockLevels: []
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `hoopsandcrosses`
const localStorageKeyStock = 'hoopsandcrosses-stock'

export const StoreProvider = ({ children }) => {
  const [lineItems, setLineItems] = React.useState(defaultValues.lineItems)
  const [stockLevels, setStockLevels] = React.useState(defaultValues.stockLevels)
  const [initialised, setInitialised] = React.useState(false)
  const [stockLoaded, setStockLoaded] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const storedItems = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (storedItems && storedItems !== `null`) {
        const parsedStoredItems = JSON.parse(storedItems);
        setLineItems(parsedStoredItems)
      }

      const storedStock = isBrowser
        ? localStorage.getItem(localStorageKeyStock)
        : null;

      if (storedStock) {
        const parsedStoredStock = JSON.parse(storedStock);
        setStockLevels(parsedStoredStock)
      }

      setInitialised(true)
    }

    initializeCheckout()
  }, [])

  const addPriceToCart = (priceId, quantity, name, variant, currency, price, image, type, priceMetadata, slug) => {
    setLoading(true)

    var existingItem = lineItems.find(i => i.priceId === priceId);
    if (existingItem) {
      updateLineItem(priceId, existingItem.quantity + parseInt(quantity, 10));
      setLoading(false)
      setDidJustAddToCart(true)
      setTimeout(() => setDidJustAddToCart(false), 1000)
      return;
    }

    const lineItem =
    {
      priceId,
      priceMetadata,
      quantity: parseInt(quantity, 10),
      name,
      variant,
      currency,
      price,
      image,
      type,
      slug
    }

    var newLineItems = [...lineItems, lineItem];
    setLineItems(newLineItems);
    localStorage.setItem(localStorageKey, JSON.stringify(newLineItems));
    setDidJustAddToCart(true)
    setTimeout(() => setDidJustAddToCart(false), 1000)
    setLoading(false)
  }

  const removeLineItem = (priceId) => {
    setLoading(true)
    var newLineItems = lineItems.filter(i => i.priceId !== priceId);
    setLineItems(newLineItems);
    localStorage.setItem(localStorageKey, JSON.stringify(newLineItems));
    setLoading(false)
  }

  const updateLineItem = (priceId, quantity) => {
    setLoading(true)
    var lineItemsCopy = [...lineItems];
    var lineItem = lineItemsCopy.find(i => i.priceId === priceId);
    lineItem.quantity = parseInt(quantity, 10);
    setLineItems(lineItemsCopy);
    localStorage.setItem(localStorageKey, JSON.stringify(lineItemsCopy));
    setLoading(false)
  }

  const updateLineItemStock = (lineItemStockCheck) => {
    setLoading(true)
    var lineItemsCopy = [...lineItems];
    lineItemStockCheck.forEach(lineItem => {
      var lineItemCopy = lineItemsCopy.find(i => i.priceId === lineItem.priceId);
      lineItemCopy.quantity = lineItem.quantity;
    })

    // Filter out items no longer in stock
    lineItemsCopy = lineItemsCopy.filter(lineItem => lineItem.quantity > 0);
    setLineItems(lineItemsCopy);
    localStorage.setItem(localStorageKey, JSON.stringify(lineItemsCopy));
    setLoading(false)
  }

  const clearCart = () => {
    setLineItems(defaultValues.lineItems)
    localStorage.setItem(localStorageKey, JSON.stringify(defaultValues.lineItems));
  }

  const updateStockLevels = (priceStocks) => {
    if (priceStocks.length == 0) {
      setStockLoaded(true)
      return;
    }

    setStockLoaded(false)
    var stockLevelsCopy = [...stockLevels];
    priceStocks.forEach(priceStock => {
      var stockLevel = stockLevelsCopy.find(i => i.priceId === priceStock.priceId)
      if (stockLevel) {
        stockLevel.st = priceStock.stockLogged
        stockLevel.pr = priceStock.preview
        stockLevel.ts = Date.now()
      }
      else {
        stockLevelsCopy.push({ priceId: priceStock.priceId, productId: priceStock.productId, st: priceStock.stockLogged, pr: priceStock.preview, ts: Date.now() })
      }
    })

    setStockLevels(stockLevelsCopy)
    localStorage.setItem(localStorageKeyStock, JSON.stringify(stockLevelsCopy));
    setStockLoaded(true)
  }

  const clearStock = () => {
    setStockLevels(defaultValues.stockLevels)
    localStorage.setItem(localStorageKeyStock, JSON.stringify(defaultValues.lineItems));
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addPriceToCart,
        removeLineItem,
        updateLineItem,
        updateLineItemStock,
        clearCart,
        clearStock,
        lineItems,
        stockLevels,
        updateStockLevels,
        initialised,
        stockLoaded,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
